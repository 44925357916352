import React from 'react';
import LanguageSwitcher from './hero/LanguageSwitcher';
import Headroom from 'react-headroom';
const Header = (props) => {
    const {appName , resultPage} = props;
    return (
            <Headroom
                    onPin={() => {
                        document.body.classList.add('hr--pinned');
                        document.body.classList.remove('hr--unpinned');
                        document.body.classList.remove('hr--unfixed');

                    }}
                    onUnfix={() => {
                        document.body.classList.add('hr--unfixed');
                        document.body.classList.remove('hr--pinned');

                    }}
                    onUnpin={() => {
                        document.body.classList.add('hr--unpinned');
                        document.body.classList.remove('hr--pinned');
                        document.body.classList.remove('hr--unfixed');
                    }}
                    pinStart={40}
                    upTolerance={0}
                    downTolerance={0}
                    disable={resultPage}
                    tag={'header'} disableInlineStyles className={'mainHeader ' + (resultPage && 'position-absolute')}>
                <div className="container-fluid">
                    <nav className="navbar navbar-dark justify-content-between">
                        <div className="d-flex align-items-center gap-1">
                            <a className="navbar-brand " href="#">
                                <svg height="20" width="115" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 925 160">
                                    <path
                                            d="m783.232 86.247.068-79.729h-24.638l-.068 79.729a46.149 46.149 0 1 1-92.3 0h.006V6.518h-24.633l-.006 79.729c0 42.88 35.732 71.185 70.786 71.2 34.412.006 70.853-27.647 70.785-71.2ZM197.782 2.558a77.4 77.4 0 1 0 77.4 77.4 77.486 77.486 0 0 0-77.4-77.4Zm52.76 77.4a52.76 52.76 0 1 1-52.76-52.76 52.82 52.82 0 0 1 52.76 52.758ZM424.335 75.9a69.46 69.46 0 0 0-69.381-69.382v24.638A44.794 44.794 0 0 1 399.7 75.9Zm.165 78.654v-24.639a44.794 44.794 0 0 1-44.743-44.743h-24.644a69.46 69.46 0 0 0 69.387 69.382Zm41.045-69.334h24.638v69.127h-24.638zm112.79 0h24.638v69.127h-24.638zM305.439 6.518h24.638v147.829h-24.638zM6.365 154.347h25.442V31.339l75.826-.183V6.518H6.365v147.829z"
                                            className="cls-1"/>
                                    <path
                                            d="M55.344 68.225h28.753v24.638H55.344zm810.981 0h28.753v24.638h-28.753zM534.099 97.149l77.054-77.054-17.421-17.422-59.633 59.632-59.632-59.632-17.422 17.422 77.054 77.054z"
                                            className="cls-1"/>
                                    <path
                                            d="M818.171 154.347h100.464v-24.638l-75.826-.183V31.339l75.826-.183V6.518H818.171v147.829z"
                                            className="cls-1"/>
                                </svg>
                            </a>
                            <div className="tool-label">
                                <span className="tool-label__title">{appName} Tool</span>
                                <span className="badge">Beta</span>
                            </div>
                        </div>
                        <div className="languages ms-auto">
                            <LanguageSwitcher/>
                        </div>
                    </nav>
                </div>
            </Headroom>
    );
};
export default Header;
